import { Data } from '../model';

import profileImg from './img/profileImage.jpg';
import bannerImg from './img/banner.jpg';
import htmlLogo from './img/technologies/html.png';
import sassLogo from './img/technologies/sass.png';
import typescriptLogo from './img/technologies/typescript.png';
import angularLogo from './img/technologies/angular.png';
import reactLogo from './img/technologies/react.png';
import dotnetLogo from './img/technologies/dotnet.png';
import csharpLogo from './img/technologies/csharp.png';
import javaLogo from './img/technologies/java.png';
import golangLogo from './img/technologies/golang.png';
import grpcLogo from './img/technologies/grpc.png';
import dockerLogo from './img/technologies/docker.png';
import kubernetesLogo from './img/technologies/kubernetes.png';
import jagwImg from './img/projects/project-jagw.png';
import serproImg from './img/projects/project-serpro.png';
import srWebsiteImg from './img/projects/project-sr-website.png';
import lspLqaImg from './img/projects/project-lsp-lqa.png';
import ckaImg from './img/certificates/cka.png';
import ccnpEntImg from './img/certificates/ccnp_enterprise.png';
import ccnpDCImg from './img/certificates/ccnp_datacenter.png';
import netacadImg from './img/certificates/netacad.png';

export const data: Data = {
  title: 'Urs Baumann',
  subtitle: 'Network Automation and Research Engineer',
  bannerImg: bannerImg,
  bannerImgAlt: 'Bridge in Rapperswil-Jona',
  profileImg: profileImg,
  profileImgAlt: 'Urs Baumann',
  aboutMe: {
    paragraphs: [
      {
        spanItems: [
          {
            text: 'Hi, I am Urs, a network automation engineer from Switzerland.',
            link: null,
          },
        ],
      },
      {
        spanItems: [
          {
            text: 'I am currently working at ',
            link: null,
          },
          {
            text: 'Eastern Switzerland University of Applied Sciences - Institute for Network and Security',
            link: 'https://www.ost.ch/de/person/urs-baumann-1381',
          },
          {
            text: ', where I am employed as an Research Engineer focusing on Network Automation, Infrastructure as Code and Artificial Intelligence.',
            link: null,
          },
        ],
      },
    ],
  },
  bio: {
    firstName: 'Urs',
    lastName: 'Baumann',
    description: 'Network Automation Engineer',
    birthday: '1987-10-20',
    email: 'webpage@m.ubaumann.ch',
    degree: 'Bachelor of Science FHO in Computer Science',
    languages: ['German', 'English', 'Parseltongue'],
  },
  socialIcons: [
    {
      name: 'GitHub',
      link: 'https://github.com/ubaumann',
      awesomeFontIcon: ['fab', 'github'],
    },
    {
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/in/ubaumannch',
      awesomeFontIcon: ['fab', 'linkedin'],
    },
    {
      name: 'Email',
      link: 'mailto:webpage@m.ubaumann.ch',
      awesomeFontIcon: ['fas', 'envelope'],
    },
  ],
  skills: [
    {
      name: 'HTML',
      progress: 1,
      img: htmlLogo,
    },
    {
      name: 'SCSS',
      progress: 0,
      img: sassLogo,
    },
    {
      name: 'Typescript',
      progress: 0,
      img: typescriptLogo,
    },
    {
      name: 'Angular',
      progress: 0,
      img: angularLogo,
    },
    {
      name: 'React',
      progress: 0,
      img: reactLogo,
    },
    {
      name: '.NET',
      progress: 10,
      img: dotnetLogo,
    },
    {
      name: 'C#',
      progress: 15,
      img: csharpLogo,
    },
    {
      name: 'Java',
      progress: 5,
      img: javaLogo,
    },
    {
      name: 'Golang',
      progress: 1,
      img: golangLogo,
    },
    {
      name: 'gRPC',
      progress: 25,
      img: grpcLogo,
    },
    {
      name: 'Docker',
      progress: 80,
      img: dockerLogo,
    },
    {
      name: 'Kubernetes',
      progress: 70,
      img: kubernetesLogo,
    },
  ],
  skillLevels: [
    {
      name: 'Novice',
      fromProgress: 0,
      upToProgress: 50,
    },
    {
      name: 'Proficient',
      fromProgress: 50,
      upToProgress: 75,
    },
    {
      name: 'Expert',
      fromProgress: 75,
      upToProgress: 100,
    },
  ],
  certificates: [
    {
      name: 'CKA: Certified Kubernetes Administrator',
      img: ckaImg,
      certificateId: 'LF-h2zh0tqhmu',
      digitalBadge:
        'https://www.credly.com/badges/c1d3475e-c25f-4bd6-a874-92a42d30081b',
    },
    {
      name: 'Cisco Certified Network Professional Enterprise',
      img: ccnpEntImg,
      certificateId: 'P6MKK8SEYKVQQWKT',
      digitalBadge:
        'https://www.credly.com/badges/4457b273-193d-4894-add6-419c2cd976a4',
    },
    {
      name: 'Cisco Certified Network Professional Data Center',
      img: ccnpDCImg,
      certificateId: '422414170152FPYN',
      digitalBadge:
        'https://www.credly.com/badges/56c11162-3cf7-45dd-a0c4-37e5260bc082',
    },
    {
      name: 'Networking Academy: Instructor 5 Years of Service',
      img: netacadImg,
      certificateId: '',
      digitalBadge:
        'https://www.credly.com/badges/7e1fd24d-d045-42ae-aa0e-8fcf46ecdc3c',
    },
  ],
  experiences: [
    {
      period: 'January 2014 - Today',
      title: 'Network Automation Research Engineer',
      employer: 'Institute for Network and Security',
      employerLink: 'https://www.ost.ch/ins',
      employmentRate: '100%',
      paragraphs: [
        {
          spanItems: [
            {
              text: 'Supervision Bachelor students, Instructor Cisco Networking Academy, Instructor SDN and network automation Cloud infrastructures, software defined networking, network automation, network unit testing, classic network training, international training in the field of Cisco DNA and Cisco ACI',
              link: null,
            },
          ],
        },
      ],
    },
    {
      period: 'August 2008 - May 2011',
      title: 'Computer and Network Engineer',
      employer: 'xerxes ag',
      employerLink: 'https://xerxes.ch/',
      employmentRate: '100%',
      paragraphs: [
        {
          spanItems: [
            {
              text: 'Support of the clients in all IT questions and responsibility for installation, configuration, commissioning and maintenance of various hardware and software',
              link: null,
            },
          ],
        },
      ],
    },
  ],
  projects: [
    {
      period: '2023 - Present',
      title: 'Pydantify',
      img: serproImg,
      projectLinks: [
        {
          name: 'GitHub',
          link: 'https://github.com/pydantify/pydantify',
          awesomeFontIcon: ['fab', 'github'],
        },
      ],
      paragraphs: [
        {
          spanItems: [
            {
              text: 'Pydantify transforms YANG models into ',
              link: null,
            },
            {
              text: 'Pydantic',
              link: 'https://docs.pydantic.dev/',
            },
            {
              text: ' models. This allows a pytonic way of working with YANG data.',
              link: null,
            },
          ],
        },
      ],
    },
    
    {
      period: '2016 - 2021',
      title: 'Network Automation Stack',
      img: serproImg,
      projectLinks: [
        
      ],
      paragraphs: [
        {
          spanItems: [
            {
              text: 'Started as a staging task automation tool to support zero touch deployment with template engine and software upgrade. Growing up to a full network automation stack with orchestration layer and integration to third-party products. Multiple customers in Switzerland.',
              link: null,
            },
          ],
        },
        {
          spanItems: [
            {
              text: 'Technologies: Python, Django, SaltStack, Jinja2, syslog, dhcp',
              link: null,
            },
          ],
        },
      ],
    },
    {
      period: '2019',
      title: 'MPLS L3 VPN provisioning and operation',
      img: serproImg,
      projectLinks: [
        
      ],
      paragraphs: [
        {
          spanItems: [
            {
              text: 'Automate the migration from a MPLS L3 network and the operation after the migration. Support deployment of new services, changing MacSec keys on point-to-point links and add new branches.',
              link: null,
            },
          ],
        },
        {
          spanItems: [
            {
              text: 'Technologies: Python, Django, Nornir, Jinja2, Restconf',
              link: null,
            },
          ],
        },
      ],
    },
  ],
};
